
<template>
  <v-dialog
    v-model="dialog"
    :disabled="!$auth.check({ resistances: 'edit' })"
    width="auto"
  >
    <template #activator="{ on }">
      <v-list-item
        class="pr-1"
        selectable
        v-on="on"
      >
        <v-list-item-action class="my-1 mr-4">
          <arkscore-gauge
            v-if="gene.resistance"
            :key="'arkscore-gauge-' + gene.result.detected ? gene.resistance.score : null"
            :value="gene.result.detected ? gene.resistance.score : null"
            :size="33"
          />
          <v-icon
            v-else
            color="error"
          >
            fas fa-exclamation-triangle
          </v-icon>
        </v-list-item-action>
        <v-list-item-content
          v-if="gene.resistance"
          :class="{ 'grey--text': !gene.result.detected }"
        >
          {{ gene.resistance.short_name.length != gene.resistance.name.en.length ? gene.resistance.short_name : gene.resistance.name.en }}
        </v-list-item-content>
        <v-list-item-content v-else>
          <v-list-item-title>
            <v-chip
              small
              color="brown lighten-1"
              dark
            >
              <v-icon
                small
                left
              >
                fa-circle-star fa-beat
              </v-icon>
              Click to categorize
            </v-chip>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-tooltip top>
            <template #activator="{ on }">
              <v-chip
                :class="{ 'grey--text': !gene.result.detected }"
                small
                outlined
                v-on="on"
              >
                {{ gene.gene | truncate(26) }}
              </v-chip>
            </template>
            {{ gene.gene }}
          </v-tooltip>
        </v-list-item-action>
        <v-list-item-action>
          <v-tooltip
            right
            :color="relevant.length ? 'success darken-1' : null"
            open-delay="500"
          >
            <template #activator="{ on, attrs }">
              <v-avatar
                size="15"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  small
                  :color="relevant.length ? 'success' : null"
                  v-text="gene.result.detected ? (relevant.length ? 'fas fa-check' : 'fal fa-check') : 'fal fa-times grey--text'"
                />
              </v-avatar>
            </template>
            <template v-if="relevant.length">
              Relevant in:
              <li
                v-for="(organism, index) in relevant.flatMap(x => x.name)"
                :key="index"
                class="mb-0"
                v-text="organism"
              />
            </template>
            <template v-else>
              Not relevant in any<br>detected organisms
            </template>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title>
        <v-chip
          outlined
        >
          <v-icon
            x-small
            class="ms-0 me-2"
          >
            fal fa-dna
          </v-icon>
          {{ gene.gene }}
        </v-chip>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-autocomplete
          v-model="gene.resistance"
          :loading="groupsLoading"
          :items="groups"
          label="Resistance Group"
          item-text="name.en"
          item-value="id"
          return-object
          menu-props="offsetY"
          prepend-icon="fal fa-shield-virus"
          no-data-text="No matching groups found"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="dialog = false"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="groupsLoading"
          @click="updateGene"
        >
          {{ $t('Update') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  components: {
    ArkscoreGauge: () => import('@/components/arkscore/ArkscoreGauge.vue'),
  },
  props: {
    gene: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    organisms: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    dialog: false,
    loading: false,
    groupsLoading: false,
    groups: [],
  }),
  computed: {
    relevant () {
      return this.gene.resistance ? this.organisms.filter(x => x.relevant_resistances.map(x => x.name.en).includes(this.gene.resistance.name.en)) : []
    },
  },
  watch: {
    dialog () {
      if (!this.groups.length) {
        this.fetchGroups()
      }
    },
  },
  methods: {
    fetchGroups () {
      this.groupsLoading = true
      const promise = this.axios.get('admin/resistances', { params:
        {
          count: 1000,
          sort: 'name',
          order: 'asc',
          quick: true,
        },
      })
      return promise.then((response) => {
        this.groups = response.data?.data
        this.groupsLoading = false
      }).catch(error => {
        this.$toast.error(error)
      })
    },
    addGroup () {
      this.newGroupLoading = true
      this.axios.post('admin/resistances', { name: this.newGroupName })
        .then((res) => {
          this.fetchGroups(this.newGroupName)
          this.organism.category = res.data
          this.newGroupDialog = false
        })
        .catch(error => {
          this.$toast.error(error)
        })
        .finally(() => {
          this.newGroupLoading = false
        })
    },
    updateGene () {
      this.loading = true
      this.axios.patch('admin/resistances/genes/' + this.gene.gene, this.gene)
        .then((res) => {
          this.gene.resistance = res.data.resistance
          this.$emit('updated', this.gene)
          this.dialog = false
        })
        .catch(error => {
          this.$toast.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
